import { version } from './version';

export const environment = {
  BASE_URL: 'https://dteolimpia.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '3533908',
  schema: 'pbi_dte_olimpia',
  groupId: 'f1001302-c3f2-4018-938b-3d30d6601214',
  analiseGeral: '7c0bf070-a03e-464f-b5e4-1bb808da96cd',
  analiseUsuarios: 'c9d02ed0-72de-4052-9b18-853de1e33e65',
  analiseComunicados: 'd5333b90-ec54-4e72-9361-9d0d7ac7620a',
  analiseProcurador: 'faa9a341-0d29-4a48-bc95-d8352fa561dd',
  analiseEventos: 'b8831504-8e7b-41d4-89c5-6e5e6405cf3a'
};
